import { Button, Form, Input } from 'antd';
import { Card } from 'antd';
import { useState } from 'react';



const Found = ({ tagDetails, messageApi }) => {
 const [state, setstate] = useState({isSending: false});
  
 const message_sent_prompt = () => {
      messageApi.open({
        type: 'success',
        content: 'Message sent to owner',
      });
    };

    const message_error_prompt = () => {
      messageApi.open({
        type: 'error',
        content: 'Message could not be sent to owner, please try again',
      });
    };
    

    const sendForm = (props) => {
      setstate({isSending: true});
      
      const requestOptions = {
        method: 'POST',

        body: JSON.stringify({ 
          finders_email: props.email, 
          finders_message: props.message })
      };

      fetch('https://yv2wajwoijlww7cwlzu3s27qsu0ycgit.lambda-url.us-east-1.on.aws/' + tagDetails.urlDroneTag, requestOptions)
      .then(response => response.json())
      .then(data => {
        message_sent_prompt();
        setstate({isSending: false});
      })
      .catch(err => {
        message_error_prompt();
      });

    };
    return (
      
       <Card title="Contact Drone Owner"  bordered={false} style={{ margin: 25, 'maxWidth':'1000px' }} loading={tagDetails.isVerified ? false : true} > 


<Form
      name="basic"
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 20 }}
      initialValues={{ remember: true }}
       onFinish={sendForm}
    //   onFinishFailed={}
    layout='horizontal'
      autoComplete="off"
      labelAlign='center'
      style={{margin:0, 'justifyContent':'center'}}
    >


<p>  <span style={{'fontSize':'15px', 'color':'grey', 'fontWeight':'bold'}}> To: </span> <span style={{'fontSize':'15px', 'color':'grey'}} >Drone Owner (***@**.**)</span>   
  </p>


<p>  <span style={{'fontSize':'15px', 'color':'grey', 'fontWeight':'bold'}}> Subject: </span> <span style={{'fontSize':'15px', 'color':'grey'}} >Someone just found your drone</span>   
  </p>
 


      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input your email' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Note"
        name="message"
        rules={[{ required: false, message: 'Feel free to leave details about the drone or additional contact information' }]}
      >
        <Input.TextArea />
      </Form.Item>

      

      <Form.Item wrapperCol={{ offset: 1, span: 1 }}>
        <Button type="primary" htmlType="submit" loading={state.isSending}>
          Send Message to Drone Owner
        </Button>
      </Form.Item>
    </Form>
    </Card>
    )
}

// need to dig to why it is not raising an issue
// Header.propTypes = {
//     title: Proptypes.string.isRequired,
// }

Found.defaultProps = {
    tagDetails: {urlDroneTag: 'blah', isVerified: false}
}

export default Found




