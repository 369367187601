
import { Badge } from 'antd';
import { Card } from 'antd';

const TagDetails = ({ tagDetails }) => {

    return (

        
        <Badge.Ribbon text="TagMyDrone" style={{margin:25, 'maxWidth':'500px'}}>
      <Card title="Tag Details" size="large"  style={{margin:25, 'maxWidth':'500px'}} loading={!tagDetails.isVerified}>
        
        <li>
          Tag URL: tagjr.co/{tagDetails.urlDroneTag} 
        </li>
        <li>
        Vendor: TagMyDrone
        </li>
      
      </Card>
    </Badge.Ribbon>
      
    )
}


TagDetails.defaultProps = {
    tagDetails: {urlDroneTag: 'blah', isVerified: false}
}

export default TagDetails




