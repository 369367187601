import { Timeline} from 'antd';
import { Card } from 'antd';

const TimelineTMD = ({ title }) => {

    return (

        <Card title="How It Works" bordered={false} style={{'margin': 25, 'maxWidth':'1000px'}} > 
    
        <Timeline>
        <Timeline.Item>Owner tags their drone using a TagJr label</Timeline.Item>
        <Timeline.Item> <p> In the event of a lost drone,</p>  a potential finder can access the unique URL on the drone's label</Timeline.Item>
        <Timeline.Item>The tag's unique URL enables the finder to get in touch with the drone owner</Timeline.Item>
        <Timeline.Item>The drone owner and the finder get connected</Timeline.Item>
      </Timeline>
      </Card>
    )
}

// need to dig to why it is not raising an issue
// Header.propTypes = {
//     title: Proptypes.string.isRequired,
// }

TimelineTMD.defaultProps = {
    title: 'Test React App'
}

export default TimelineTMD


