import React from 'react';
import { Layout, Menu, message } from 'antd';
import {
  CopyrightCircleFilled,
  AlertTwoTone
} from '@ant-design/icons';
import { Typography } from 'antd';
import Found from './components/Found';
import TimelineTMD from './components/TimelineTMD';
import TagDetails from './components/TagDetails'
import { Avatar } from 'antd';
import { useState, useEffect }  from 'react'

const { Title } = Typography;

const { Header, Content, Footer } = Layout;

function goToTagMyDrone () {
  window.location.href = 'https://tagmydrone.com';
};

const navItems = [{key:'1', label:'TagJr Lost and Found'}, 
                {key:'2', label:'About Us', onClick:goToTagMyDrone}, 
               ];
const year = new Date().getFullYear();

const App = () => {
  const [messageApi, ContextHolder] = message.useMessage();
  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Unable to verify tag, please double check the tag URL and try again.',
    });
  };

  const valid_tag = () => {
    messageApi.open({
      type: 'success',
      content: 'Active Tag',
    });
  };

  // let tag = new URLSearchParams(document.location.search).get("tag");
  // tag = tag == null ? "empty" : tag;

  const tag = window.location.pathname.replace('/','')
  console.log(tag);
  const [tagDetails, setTagDetails] = useState({
    urlDroneTag: tag.toUpperCase(),
    attemptedTag:'',
    isVerified: false,
    hasLoaded: false
  });

  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();

  useEffect( ()=> {
     if (tagDetails.urlDroneTag !== tagDetails.attemptedTag) {
      fetch('https://6hzx36fwoi4j3vuhghkgaiunsy0dnyen.lambda-url.us-east-1.on.aws/' + tagDetails.urlDroneTag)
      .then(response => response.json())
      .then(data => {
        
        if (!data.isVerified) 
        {
          error();
        }else
        {
          valid_tag();
        }
        //change the isVerified to mimick the exact value of the api once debuging is over
        setTagDetails({ ...tagDetails, 
          isVerified:data.isVerified, 
          attemptedTag: tagDetails.urlDroneTag});
      })
      .catch(err => { 
          error();
          setTagDetails({ ...tagDetails,
          isVerified: false,
          attemptedTag: tagDetails.urlDroneTag })      
      } )
     }
  });
  //const location = useLocation();
  //console.log(window.location.pathname.replace('/',''));

  return (

    <Layout style={{"minWidth": '100wh', "minHeight": '100vh', overflow: "auto",  'alignItems':'center', display:'flex'}}>
      {ContextHolder}
      
      <Header className="header" style={{'minWidth':'100%'}} >
        <div style={{display:'flex', 'justifyContent':'spaceBetween'}} > 


          <div> 
                <div className="logo" style={{"color":'white', "fontWeight": 'bold', 'fontSize':'25px' }}  >
                <Avatar size={64} icon={<AlertTwoTone />} /> 
                  Tagjr.co | Drone Registry   
                </div>
          </div>
          <div> 
            <Menu  theme="dark" mode="horizontal" defaultSelectedKeys={['1']} items={navItems} />
          </div>

          
      </div>
      </Header>
      <Layout>
     
        <Layout
          style={{
            padding: '0 24px 24px',
            "flex":'1 1 100%',
          }}
        >
 <Title level={2} style={{margin:25}}>Just found a drone?  </Title>
 <p style={{margin:25, 'maxWidth':'500px', 'lineHeight':'25px'}} > 
 Thank you for accessing the drone's finder webpage. You can use the form below to get in touch with the drone's owner. We highly recommed double checking the tag details below before proceeding.  
 </p>

 
        <TagDetails tagDetails={tagDetails}></TagDetails>


        <Found tagDetails={tagDetails} messageApi={messageApi}></Found> 


          <Content>

            <TimelineTMD></TimelineTMD>
          </Content>
        </Layout>
      
      <Layout style={{ flex:"1 1 100px"}}>
         <Footer style={{align:'center'}} > HeavenTech (Tagjr.co) All Rights Reserved {year} <CopyrightCircleFilled />   </Footer>
         
        </Layout>

        </Layout>


    </Layout>
  );
};
export default App;
